import React, { useState, useEffect, useRef, useCallback } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
import { newsEnvUrl } from '../utils/url'
import logo from '../static/images/logo-bottom.svg'
import FooterLanguages from '../components/footerLanguages'


export default function Footer() {
  const intl = useIntl()
  const year = (new Date()).getFullYear()

  return (
    <>
      <div className={'footer-box'}>
        <div className={'footer'}>
          <div className={'footer-img'}>
            <Link to="/">
              <img alt="logo" src={logo} />
            </Link>
          </div>
          <div className={'footer-content'}>
            <dl>
              <dt>{intl.formatMessage({ id: 'ABOUT' })}</dt>
              <dd>
                <Link to="/getab/">{intl.formatMessage({ id: 'Economy' })}</Link>
              </dd>
              <dd>
                <Link to="/contact/">{intl.formatMessage({ id: 'Contact Us' })}</Link>
              </dd>
              <dd>
                <Link to="/mediakit/">{intl.formatMessage({ id: 'Media Kit' })}</Link>
              </dd>
              <dd>
                <Link to={'/roadmap/'} target="_blank">
                  {intl.formatMessage({ id: 'Roadmap' })}
                </Link>
              </dd>
            </dl>
            <dl>
              <dt>{intl.formatMessage({ id: 'SERVICE TERMS' })}</dt>
              <dd>
                <Link to="/copyright/">{intl.formatMessage({ id: 'Copyright' })}</Link>
              </dd>
              <dd>
                <Link to="/terms-of-use/">{intl.formatMessage({ id: 'Terms of Use' })}</Link>
              </dd>
              <dd>
                <Link to="/privacy/">{intl.formatMessage({ id: 'Privacy Policy' })}</Link>
              </dd>
            </dl>
          </div>
        </div>
        <div className={'footer-copyright'}>
          <FooterLanguages />
          <p>{intl.formatMessage({ 
            id: 'Copyright © 2025 AB Foundation. All Rights Reserved.', 
            defaultMessage: 'Copyright © 2025 AB Foundation. All Rights Reserved.', 
          }, {year: year})}</p>
        </div>
      </div>
      <FooterMobile />
    </>
  )
}

const FooterMobile = () => {
  const intl = useIntl()
  const year = (new Date()).getFullYear()

  return (
    <div className={'footer-mobile'}>
      <div className={'footer-img'}>
        <Link to="/">
          <img alt="logo" src={logo} />
        </Link>
      </div>
      <div className={'footer-content'}>
        <dl className={'footer-item3'}>
          <dt>{intl.formatMessage({ id: 'ABOUT' })}</dt>
          <dd>
            <Link to="/getab/">{intl.formatMessage({ id: 'Economy' })}</Link>
          </dd>
          <dd>
            <Link to="/contact/">{intl.formatMessage({ id: 'Contact Us' })}</Link>
          </dd>
          <dd>
            <Link to="/mediakit/">{intl.formatMessage({ id: 'Media Kit' })}</Link>
          </dd>
          <dd>
            <Link to={'/roadmap/'} target="_blank">
              {intl.formatMessage({ id: 'Roadmap' })}
            </Link>
          </dd>
        </dl>
        <dl className={'footer-item4'}>
          <dt>{intl.formatMessage({ id: 'SERVICE TERMS' })}</dt>
          <dd>
            <Link to="/copyright/">{intl.formatMessage({ id: 'Copyright' })}</Link>
          </dd>
          <dd>
            <Link to="/terms-of-use/">{intl.formatMessage({ id: 'Terms of Use' })}</Link>
          </dd>
          <dd>
            <Link to="/privacy/">{intl.formatMessage({ id: 'Privacy Policy' })}</Link>
          </dd>
        </dl>
      </div>
      <div className={'footer'}>
        <div className={'footer-copyright'}>
          <FooterLanguages />
          <p>{intl.formatMessage({ 
            id: 'Copyright © 2025 AB Foundation. All Rights Reserved.', 
            defaultMessage: 'Copyright © 2025 AB Foundation. All Rights Reserved.', 
          }, {year: year})}</p>
        </div>
      </div>
    </div>
  )
}
