import React, { useState } from "react"
import languageIcon from "../static/images/language.png"
import pathIcon from "../static/images/path.png"
import { useIntl, IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl-v6'
import { languageName } from '../utils/language'

const FooterLanguages = () => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const locale = intl.locale
  
  const handleLanguageChange = (language: string) => {
    changeLocale(language);
  }

  return (
    <div className="relative inline-block group h-12 footer-language">
      {/* 触发按钮 - 同时支持 Click 和 Hover */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between rounded transition-colors"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div className="flex flex-row">
          <img src={languageIcon} className="w-5 h-5"/>
          <span>{languageName[locale]}</span>
        </div>
        <img src={pathIcon} className="arrow" />
      </button>

      {/* 下拉内容 - 同时响应 Hover */}
      <div
        className={`language-items absolute shadow-lg rounded-lg z-10 transition-opacity ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <IntlContextConsumer>
          {({ languages, language: currentLocale }: any) =>
            languages.map((language: any) => (
              <span
                key={language}
                onClick={() => handleLanguageChange(language)}
                className={currentLocale === language ? 'current' : ''}
              >
                {languageName[language]}
              </span>
            ))
          }
        </IntlContextConsumer>
      </div>
    </div>
  )
}

export default FooterLanguages