import React from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import { StaticImage } from 'gatsby-plugin-image'
import bannerEcosystem from '../static/images/ecosystem/ecosystem-banner.png'
import bannerEcosystemH5 from '../static/images/ecosystem/ecosystem-banner-h5.png'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'

export default Ecosystem

function Ecosystem() {
  let pageModel = new PageModelTitle('Ecosystem', 'ecosystem-html')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()

  return (
    <div id={'ecosystem'}>
      <div className={'getnew-banner'}>
        {/* <img className={'ecosystem-banner-pc'} src={bannerEcosystem} alt="img" />
        <img className={'ecosystem-banner-h5'} src={bannerEcosystemH5} alt="img" /> */}
        <h2>{intl.formatMessage({ id: 'ECOSYSTEM' })}</h2>
      </div>
      <div className={'ecosystem-content ecosystem-container container'}>
        <dl>
          <dt>
            <i></i>
            {intl.formatMessage({ id: 'NFT' })}
          </dt>
          <dd className={'new-mall'}>
            <a href="https://newmall.io/" target="_blank">
              <StaticImage placeholder="blurred" alt="new-mall" src="../static/images/ecosystem/new-mall.png" />
              <span>{intl.formatMessage({ id: 'Leading NFT Market.' })}</span>
            </a>
          </dd>
          {/* <dd className={'new-asset'}>
            <a href={newsEnvUrl + '/newasset-generator/'} target="_blank">
              <StaticImage placeholder="blurred" alt="new-asset" src="../static/images/ecosystem/new-asset.png" />
              <span>{intl.formatMessage({ id: 'Token Generator Tool on NewChain.' })}</span>
            </a>
          </dd> */}
        </dl>
        <dl>
          <dt>
            <i></i>
            {intl.formatMessage({ id: 'Wallet' })}
          </dt>
          <dd className={'new-mall'}>
            <Link to="/abwallet/">
              <StaticImage placeholder="blurred" alt="ab-wallet" src="../static/images/ecosystem/ab-wallet.png" />
              <span>{intl.formatMessage({ id: 'Decentralized Digital Wallet Of Newton Community Economy.' })}</span>
            </Link>
          </dd>
          {/* <dd className={'new-mask'}>
            <a
              href="https://addons.mozilla.org/zh-CN/firefox/addon/newmask/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search"
              target="_blank"
            >
              <StaticImage placeholder="blurred" alt="new-mask" src="../static/images/ecosystem/new-mask.png" />
              <span>
                {intl.formatMessage({ id: 'An extension for accessing NewChain enabled distributed applications.' })}
              </span>
            </a>
          </dd> */}
        </dl>
        {/* <dl>
          <dt>
            <i></i>
            {intl.formatMessage({ id: 'Cross-chain' })}
          </dt>
          <dd className={'new-bridge'}>
            <a href="https://newbridge.network/" target="_blank">
              <StaticImage placeholder="blurred" alt="new-bridge" src="../static/images/ecosystem/new-bridge.png" />
              <span>
                {intl.formatMessage({ id: 'Supports Inter-Chain token transfer between NewChain and others.' })}
              </span>
            </a>
          </dd>
        </dl> */}
        <dl>
          <dt>
            <i></i>
            {intl.formatMessage({ id: 'Metaverse' })}
          </dt>
          <dd className={'andverse'}>
            <a href="https://www.andverse.org/" target="_blank">
              <StaticImage placeholder="blurred" alt="andverse" src="../static/images/ecosystem/andverse.png" />
              <span>{intl.formatMessage({ id: 'A decentralized multigalactic metaverse.' })}</span>
            </a>
          </dd>
        </dl>
        {/* <dl>
          <dt>
            <i></i>
            {intl.formatMessage({ id: 'DEX' })}
          </dt>
          <dd className={'new-swap'}>
            <a href="https://newswap.org/" target="_blank">
              <StaticImage placeholder="blurred" alt="new-swap" src="../static/images/ecosystem/new-swap.png" />
              <span>{intl.formatMessage({ id: 'Decentralized Exchange Protocol on NewChain.' })}</span>
            </a>
          </dd>
          <dd>
            <a href="https://www.binance.org/" target="_blank" className={'econmy'}>
              <StaticImage placeholder="blurred" alt="img" src="../static/images/getnew/listed5.png" />
              <span>{intl.formatMessage({ id: 'The best-performing decentralized economy.' })}</span>
            </a>
          </dd>
        </dl> */}
        <dl>
          <dt>
            <i></i>
            {intl.formatMessage({ id: 'Exchange' })}
          </dt>
          <dd className={'exchange1'}>
            <a href="https://bitget.com/" target="_blank">
              <StaticImage placeholder="blurred" alt="img" src="../static/images/ecosystem/bitget-l.png" />
              <span>{intl.formatMessage({ id: 'Helping users trade smarter by providing a secure, one-stop crypto investment solution.' })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://www.htx.com/" target="_blank">
              <StaticImage placeholder="blurred" alt="img" src="../static/images/ecosystem/htx-l.png" />
              <span>{intl.formatMessage({ id: 'Leading Crypto Exchange for BTC, ETH, XRP, and 600+ Altcoins.' })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://www.mexc.com/" target="_blank">
              <StaticImage placeholder="blurred" alt="mexc" src="../static/images/ecosystem/mexc-l.png" />
              <span>{intl.formatMessage({ id: 'The First User-friendly Cryptocurrency Service Provider.' })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://www.gate.io/signup?ch=signupAB" target="_blank">
              <StaticImage placeholder="blurred" alt="mexc" src="../static/images/ecosystem/gate.png" />
              <span>{intl.formatMessage({ id: 'Trade over 3,800 cryptocurrencies safely, quickly, and easily' })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://bingx.com" target="_blank">
              <StaticImage placeholder="blurred" alt="bingx" src="../static/images/ecosystem/bingx.png" />
              <span>{intl.formatMessage({ id: "OFFICIAL CRYPTO EXCHANGE PARTNER OF CHELSEA FC" })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://bitmart.com" target="_blank">
              <StaticImage placeholder="blurred" alt="bitmart" src="../static/images/ecosystem/bitmart.png" />
              <span>{intl.formatMessage({ id: "BitMart is a crypto exchange for everyone" })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://lbank.com" target="_blank">
              <StaticImage placeholder="blurred" alt="bitmart" src="../static/images/ecosystem/Lbank.png" />
              <span>{intl.formatMessage({ id: 'A cryptocurrency exchange trusted by millions of users' })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://www.hotcoin.com" target="_blank">
              <StaticImage placeholder="blurred" alt="bitmart" src="../static/images/ecosystem/hotcoin.png" />
              <span>{intl.formatMessage({ id: "The world's leading blockchain Digital asset trading platform" })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://ueex.com" target="_blank">
              <StaticImage placeholder="blurred" alt="bitmart" src="../static/images/ecosystem/UEEX.png" />
              <span>{intl.formatMessage({ id: 'UEEx makes trading easler' })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://www.eeee.com" target="_blank">
              <StaticImage placeholder="blurred" alt="eeee" src="../static/images/ecosystem/4e-l.png" />
              <span>{intl.formatMessage({ id: "The world's leading financial asset trading platform." })}</span>
            </a>
          </dd>
          <dt className='upcoming'>{intl.formatMessage({ id: 'Upcoming Exchange' })}</dt>
          <dd className={'exchange1'}>
            <a href="https://biconomy.com" target="_blank">
              <StaticImage placeholder="blurred" alt="bitmart" src="../static/images/ecosystem/biconomy.png" />
              <span>{intl.formatMessage({ id: 'Global Leading Crypto Exchange' })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://weex.com" target="_blank">
              <StaticImage placeholder="blurred" alt="bitmart" src="../static/images/ecosystem/weex.png" />
              <span>{intl.formatMessage({ id: 'Trade with Precision, Succeed with WEEX' })}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://digifinex.com" target="_blank">
              <StaticImage placeholder="blurred" alt="bitmart" src="../static/images/ecosystem/digifinex.png" />
              <span>{intl.formatMessage({ id: 'Beyond Your Wealth Companion, Celeberate, Trade, and Prosper Together'})}</span>
            </a>
          </dd>
          <dd className={'exchange1'}>
            <a href="https://btse.com" target="_blank">
              <StaticImage placeholder="blurred" alt="bitmart" src="../static/images/ecosystem/btse.png" />
              <span>{intl.formatMessage({ id: 'Investing Made Simple' })}</span>
            </a>
          </dd>
        </dl>
      </div>
      <div className={'ecosystem-submit'}>
        {/* <a href="https://github.com/newtonproject/ecosystem-projects" target="_blank" className={'submit-box'}>
          <i className={'submit'}>{intl.formatMessage({ id: 'Submit Your project' })}</i>
        </a> */}
      </div>
    </div>
  )
}
